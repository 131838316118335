import React from 'react';
import styled from 'styled-components';
import { Box, Title3, Large, colors } from 'js-components';
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper';
import { Helmet } from 'react-helmet';
import { PageTitle, PageTitle2 } from '../../components/PageTitle';
import Default from '../../components/Layouts/Default';

import PushToStart from '../images/pushToStart.png';
import ClickToCall from '../images/clickToCall.svg';
import IntelligentPhoneNumbers from '../images/intelligentPhoneNumbers.png';

const SolutionsPage = () => (
  <Default>
    <Helmet>
      <title>Truly | Powering More Sales Conversations</title>
    </Helmet>

    <WrapperBackground color={colors.trulyDark} background="businessScene1">
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width="1">
          <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            Don't Let Your Reps Fear the Phone
          </PageTitle2>
          <PageTitle color={colors.white} mb="1.5rem">
            Drive More Talk Time By Giving Your Reps a Delightful Calling Experience
          </PageTitle>
        </Box>
      </Section>
    </WrapperBackground>

    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1 / 2, 1 / 2]} pb="2rem">
          <WidthImg src={PushToStart} alt="Feature List"/>
        </Box>
        <Box width={[1, 1 / 2]}>
          <IconImg src={ClickToCall} />
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
            EVERY FEATURE, BUILT JUST FOR THEM
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            Most sales calling solutions are repurposed call center products, which don't fit the dynamic workflow needs of the modern revenue team (always on, mobile and relationship-driven)
            <br/><br/>
              Truly was built from the ground to address the stringent needs of reps at leading SaaS organizations like Square, WeWork, UBER and Flexport, making it purpose-built to solve the needs 
            <br/><br/>
            As a result, our customers see a 10% increase in call activity in 30 days without changing any rep behavior.

          </Large>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperBackground color={colors.trulyDark} background="headsetPerson1">
      <Section flexDirection="row" py="12rem" background={colors.trulyDark}>
        <Box width={[1, 1 / 2]} pl={['0pt', '32pt']}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
            CALL/TEXT ANYWHERE, ANYTIME AND ON ANY DEVICE
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem">
              36% of inside sales reps' talk time take place on a mobile phone, leading to a loss of valuable reporting/tablk time data.
            <br/><br/>
            Our solution is the only one to treat mobile, deskphone and conference room calling as first class citizens, ensuring you can engage customers from anywhere.
            <br/><br/>
            This way, you empower reps, eliminate excuses, and drive a better customer experience.
          </Large>
        </Box>
        <Box width={[0, 1 / 2]} />
      </Section>
    </WrapperBackground>

    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1 / 2, 1 / 2]} pb="2rem">
          <WidthImg src={IntelligentPhoneNumbers} alt="Phone Numbers"/>
        </Box>
        <Box width={[1, 1 / 2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
            BETTER CALLING INFRASTRUCTURE TO DRIVE MORE CONNECTS
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
              Sales communication tools use third parties that recycle numbers (think dating/craigslist apps) and cross your traffic with low value calls.  This results in your reps getting blocked as SPAM and sounding like they're coming from a foreign call center.
              <br/><br/>
              Truly has exclusive carrier partnerships and dedicated infrastructure that ensure you have the best call quality achievable for VOIP.  We can afford to do this because of superior ownership of our supply chain, and our value-add software that drives better revenue outcomes.
              <br/><br/>
              This means your calls don't get blocked, and you can keep customers on the phone longer when they answer the phone.
          </Large>
        </Box>
      </Section>
    </WrapperSolid>
  </Default>
);

export default SolutionsPage;

const WidthImg = styled.img`
  display: block;
  max-width: 100%;
`;

const IconImg = styled.img`
  display: block;
  width: 100px;
  padding: 16pt;
  height: auto;
  margin: 0 auto;
`;
